import { memo } from "react";
import { DEMO_LOCATION_TYPES, LEAD_STATUS } from "../../utils/constants/lead.constants";
import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import FileInput from "../../components/inputs/FileInput";
import DynamicDataDropDown from "../../components/inputs/DynamicDataDropDown";
import { useSelector } from "react-redux";
import { Box, Button, ButtonGroup, MenuItem } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import getReasonApi from "../../apis/reason.api";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { ChildProductsList, ParentProductsList } from "../product/ProductDropDowns";

const LeadUpdateStatusUi = ({
  title,
  modalKey,
  fields,
  status,
  setFields,
  loading,
  onSubmit,
}) => {
  const { product } = useSelector((state) => state);
  return (
    <CustomDialog
      id={modalKey}
      loading={loading}
      err={fields.err}
      onSubmit={onSubmit}
      title={`${title}`}
      closeText="Close"
      confirmText={`Submit`}
    >
      <Box pt={2}>


        {
          (status == LEAD_STATUS.WILLING_TO_PURCHASE) &&
          <ButtonGroup fullWidth sx={{ mb: 2 }} disableElevation>
            <Button variant={fields.status == LEAD_STATUS.WILLING_TO_PURCHASE ? "contained" : "outlined"} onClick={() => setFields({ ...fields, status: LEAD_STATUS.WILLING_TO_PURCHASE, audiogram: null })} >Customer Interested</Button>
            {fields.isMedicalProduct && <Button variant={fields.status == LEAD_STATUS.AUDIOGRAM_RECEIVED ? "contained" : "outlined"} onClick={() => setFields({ ...fields, status: LEAD_STATUS.AUDIOGRAM_RECEIVED, audiogram: null })} >Add Audiogram</Button>}
            <Button variant={fields.status == LEAD_STATUS.NOT_INTERESTED ? "contained" : "outlined"} onClick={() => setFields({ ...fields, status: LEAD_STATUS.NOT_INTERESTED, audiogram: null })}>Not Interested</Button>
          </ButtonGroup>
        }

        {
          (fields.status == LEAD_STATUS.AUDIOGRAM_APPROVED || fields.status == LEAD_STATUS.AUDIOGRAM_REJECTED) &&
          <ButtonGroup fullWidth sx={{ mb: 2 }} disableElevation>
            <Button variant={fields.status == LEAD_STATUS.AUDIOGRAM_APPROVED ? "contained" : "outlined"} onClick={() => setFields({ ...fields, status: LEAD_STATUS.AUDIOGRAM_APPROVED, suggested_products: null })} >Product Suitable</Button>
            <Button variant={fields.status == LEAD_STATUS.AUDIOGRAM_REJECTED ? "contained" : "outlined"} onClick={() => setFields({ ...fields, status: LEAD_STATUS.AUDIOGRAM_REJECTED, suggested_products: null })}>Product Not Suitable</Button>
          </ButtonGroup>
        }

        {fields.status == LEAD_STATUS.AUDIOGRAM_RECEIVED && (
          <FileInput
            sx={{ width: "100%" }}
            onChange={(file) => {
              setFields({ ...fields, audiogram: file });
            }}
            defaults={fields.audiogram ? [fields.audiogram] : []}
            accept=".pdf"
            title="Upload Audiogram*"
            subTitle="Only pdf files are allowed! less than 1 MB"
          />
        )}

        {fields.status == LEAD_STATUS.AUDIOGRAM_APPROVED && (
          <DynamicDataDropDown
            fullWidth
            disabled={loading}
            onChange={(e, val) =>
              setFields({ ...fields, suggested_products: val })
            }
            defaultValue={fields.suggested_products}
            reducerDataObj={{
              loading: product.loading,
              data: product.data?.sort((a, b) => b?.is_medical_product - a?.is_medical_product)
            }}
            titleKey="product_name"
            valueKey="_id"
            inputProps={{
              label: "Product Suggestion*",
            }}
          />
        )}
        <>
          {fields.status == LEAD_STATUS.DISPATCHED_FOR_DEMO && (
            <>
              <CustomInput
                disabled={loading}
                value={fields.box_id}
                onChange={(e) =>
                  setFields({ ...fields, err: "", box_id: e.target.value })
                }
                type="text"
                label={"Box Id*"}
              />
              <FileInput
                disabled={loading}
                sx={{ width: "100%" }}
                onChange={(file) => {
                  setFields({ ...fields, payment_proof: file });
                }}
                defaults={fields.payment_proof ? [fields.payment_proof] : []}
                accept=".pdf"
                title="Payment Proof*"
                subTitle="Only pdf files are allowed! less than 1 MB"
              />
            </>
          )}
        </>

        <>
          {fields.status == LEAD_STATUS.DEMO_SCHEDULED && (
            <>
              <DesktopDatePicker
                disabled={loading}
                inputFormat="DD/MM/YYYY"
                value={fields.demo_date ?? null}
                onChange={(changedVal) =>
                  setFields({ ...fields, err: "", demo_date: changedVal?.toISOString() })
                }
                renderInput={(props) => <CustomInput {...props} />}
                type="text"
                label={"Date*"}
              />
              <ButtonGroup fullWidth sx={{ mb: 2, mt: 2 }} disableElevation>
                <Button variant={fields.demo_location_type == DEMO_LOCATION_TYPES.HOME ? "contained" : "outlined"} onClick={() => setFields({ ...fields, demo_location_type: DEMO_LOCATION_TYPES.HOME, demo_location_address: fields.address })} >Home</Button>
                <Button variant={fields.demo_location_type == DEMO_LOCATION_TYPES.OFFICE ? "contained" : "outlined"} onClick={() => setFields({ ...fields, demo_location_type: DEMO_LOCATION_TYPES.OFFICE, demo_location_address: null })}>Office</Button>
              </ButtonGroup>
              {fields.demo_location_type == DEMO_LOCATION_TYPES.HOME && <CustomInput
                multiline
                rows={2}
                disabled={loading}
                value={fields.demo_location_address}
                onChange={(e) =>
                  setFields({ ...fields, err: "", demo_location_address: e.target.value })
                }
                type="text"
                label={"Address*"}
              />}
            </>
          )}
        </>

        {fields.status == LEAD_STATUS.NOT_INTERESTED &&
          <AsyncDropDown
            lazyFun={async (params) =>
              await getReasonApi({
                ...params,
              })
            }
            defaultVal={fields.rejection_reason}
            label="Select Rejection Reason*"
            OptionComponent={({ option, ...rest }) => {
              return <MenuItem {...rest}>{option.title}</MenuItem>;
            }}
            onChange={async (changedVal) => {
              setFields({
                ...fields,
                rejection_reason: changedVal ? changedVal.title : null,
              });
            }}
            titleKey={"title"}
            valueKey={"_id"}
          />
        }
        {fields.status == LEAD_STATUS.NOT_WORKING &&

          <ParentProductsList
            fullWidth
            multiple
            // defaultValue={defaulData?.product ?? null}
            key={fields.type?._id}
            onChange={(e, val) =>
              setFields({ ...fields, not_worked_products: val?.map?.(item => item?._id) })
            }

            inputProps={{
              label: "Select Tested Products*"
            }}

          />
        }

        {fields.status == LEAD_STATUS.PURCHASED &&
          <>

            <ParentProductsList
              fullWidth

              onChange={(e, val) =>
                setFields({ ...fields, purchased_parent_product: val?._id ?? null, purchased_product: null })
              }

              inputProps={{
                label: "Select Purchased Product*",
                margin:"dense"
              }}

            />

            <ChildProductsList
              fullWidth
             key={fields.purchased_parent_product}
              parent={fields.purchased_parent_product}
              onChange={(e, val) =>
                setFields({ ...fields, purchased_product: val?._id ?? null })
              }

              inputProps={{
                label: "Select Sub Product*",
                margin:"dense"
              }}

            />            <CustomInput

              disabled={loading}
              value={fields.purchased_product_price}
              onChange={(e) =>
                setFields({ ...fields, err: "", purchased_product_price: e.target.value })
              }
              type="number"
              label={"Purchased Product Price*"}
            />
          </>
        }
        <CustomInput
          multiline
          rows={3}
          disabled={loading}
          value={fields.remarks}
          onChange={(e) =>
            setFields({ ...fields, err: "", remarks: e.target.value })
          }
          type="text"
          label={"Remarks*"}
        />
      </Box>
    </CustomDialog>
  );
};
export default memo(LeadUpdateStatusUi);
