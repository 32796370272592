import {
  Button,
  ButtonGroup,
  MenuItem,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import SubmitButton from "../../components/button/SubmitButton";
import { useSelector } from "react-redux";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import LeadCounts from "./LeadCount";
import { fetchAllowedStatusAccrodingAnalyticSatatus } from "../../utils/helpers/lead.helper";
import { findObjectKeyByValue } from "../../utils/helpers/main";
import { LEAD_STATUS } from "../../utils/constants/lead.constants";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import { USER_ROLES } from "../../utils/constants/main";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getUserApi } from "../../apis/user.api";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  fetchList,
  loading,

  columns,
}) => {
  const { user } = useSelector((state) => state);
  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox mb={2}>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2} sx={{ display: "flex" }}>
                <Box mr={2} />
              </Box>
            </FilterTitleBox>
            <FiltersBox mt={3}>
              <PaddingBoxInDesktop flex={1}>
                <TimeRangeSelector
                  onChange={(val) => setFilters({ ...filters, ...val })}
                />
              </PaddingBoxInDesktop>

              {user?.data?.role != USER_ROLES.DISTRIBUTOR && (
                <PaddingBoxInDesktop flex={1} ml={3}>
                  {
                    <ButtonGroup disableElevation fullWidth>
                      <Button
                        variant={
                          !filters.hasDistributor ? "contained" : "outlined"
                        }
                        onClick={() =>
                          setFilters({
                            ...filters,
                            hasDistributor: null,
                            userId: null,
                          })
                        }
                      >
                        All
                      </Button>
                      <Button
                        variant={
                          filters.hasDistributor == "false"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          setFilters({
                            ...filters,
                            hasDistributor: "false",
                            userId: null,
                          })
                        }
                      >
                        Company Leads
                      </Button>
                      <Button
                        variant={
                          filters.hasDistributor == "true"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          setFilters({ ...filters, hasDistributor: "true" })
                        }
                      >
                        Distributor Leads
                      </Button>
                    </ButtonGroup>
                  }

                  {filters.hasDistributor == "true" && (
                    <AsyncDropDown
                      lazyFun={async (params) =>
                        await getUserApi({
                          ...params,
                          role: USER_ROLES.DISTRIBUTOR,
                        })
                      }
                      label="Select Distributor*"
                      OptionComponent={({ option, ...rest }) => {
                        return <MenuItem {...rest}>{option.name}</MenuItem>;
                      }}
                      onChange={async (changedVal) => {
                        setFilters({
                          ...filters,
                          userId: changedVal ? changedVal._id : null,
                        });
                      }}
                      titleKey={"name"}
                      valueKey={"_id"}
                    />
                  )}
                </PaddingBoxInDesktop>
              )}
            </FiltersBox>
            <LeadCounts setFilters={setFilters} filters={filters} />

            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex" }} mt={4}>
                {user.data.role != USER_ROLES.employee && (
                  <Box>
                    {" "}
                    <SubmitButton
                      loading={loading}
                      variant="contained"
                      onClick={() => fetchList(true)}
                      title={"Export"}
                    />
                  </Box>
                )}
              </Box>

              <FiltersBox mt={4}>
                <PaddingBoxInDesktop
                  mb={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  pl={3}
                >
                  <ButtonGroup>
                    {fetchAllowedStatusAccrodingAnalyticSatatus(
                      filters.parentStatus
                    ).length > 0 && (
                      <Button
                        variant={!filters.status ? "contained" : "outlined"}
                        onClick={() => setFilters({ ...filters, status: null })}
                      >
                        All
                      </Button>
                    )}
                    {fetchAllowedStatusAccrodingAnalyticSatatus(
                      filters.parentStatus
                    )?.map((item) => (
                      <Button
                        key={item}
                        variant={
                          filters.status == item ? "contained" : "outlined"
                        }
                        onClick={() => setFilters({ ...filters, status: item })}
                      >
                        {findObjectKeyByValue(item, LEAD_STATUS)}
                      </Button>
                    ))}
                  </ButtonGroup>
                </PaddingBoxInDesktop>
                <PaddingBoxInDesktop
                  mb={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                  pl={3}
                >
                  <AsynSearchBar
                    fullWidth
                    title="Search By Name/Phone "
                    size="small"
                    placeholder={"Search By Name/Phone "}
                    defaultValue={filters.search}
                    onChange={(changedVal) => {
                      setFilters({ ...filters, search: changedVal });
                    }}
                  />
                </PaddingBoxInDesktop>
              </FiltersBox>
            </Box>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;
