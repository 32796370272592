import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { useMemo } from "react";


import moment from "moment";
import { findObjectKeyByValue } from "../../utils/helpers/main";
import { CALLPOOL_STATUS } from "../../utils/constants/lead.constants";
import { getCallPoolListApi } from "../../apis/callpool.api";

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Callpool ";
  const fetchApi = getCallPoolListApi;
  

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "call_time",
        label: "Call At",
        align: "left",
        sort: true,
        renderValue:(params)=>moment(params.call_time).format("DD/MM/YYYY hh:mm")
      },
      {
        id: 2,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        sort: true,
      },
      {
        id:3,
        fieldName: "status",
        label: "Status",
        align: "left",
        renderValue:(params)=>findObjectKeyByValue(params.status,CALLPOOL_STATUS)
      }

     
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["phone"],
    sort: "call_time",
    status:CALLPOOL_STATUS.PENDING,
    sortDirection: -1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };


  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
